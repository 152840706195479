<template>
	<div class="container-fluid" ref="container" style="position: absolute; left: 0; top: 0; z-index: 50; background: #fff; min-height: 100vh">
		<Toast />

		<div class="clearfix" style="padding: 6px; border-bottom: 1px solid #ccc">
			<div>
				<div style="float: right; padding-top: 3px; padding-right: 12px;">
					<InputSwitch @change="togglePresent" v-model="isPresent" />
				</div>
				<div style="float: right; padding-top: 3px; padding-left: 10px;">
					<span style="font-weight: bold; font-size: 20px;">Present: &nbsp; </span>
				</div>

				<div v-if="isPresent" class="pull-right">

					<router-link to="/radiologist/reports/pending" class="btn" :class="{'btn-secondary': !openPoolCount, 'btn-info': openPoolCount>0}" style="margin-right: 5px;">
						System Pool ({{ openPoolCount }})
					</router-link>
					<router-link to="/radiologist/reports/specific" class="btn" :class="{'btn-secondary': !directPoolCount, 'btn-success': directPoolCount>0}" style="margin-right: 5px">
						Direct Pool ({{ directPoolCount }})
					</router-link>
					<router-link to="/radiologist/reports/review" class="btn" :class="{'btn-secondary': !reviewPoolCount, 'btn-warning': reviewPoolCount>0}" style="margin-right: 5px">
						Review Pool ({{ reviewPoolCount }})
					</router-link>

				</div>
	
				<h4 style="font-weight: bold;">
					<router-link to="/radiologist/dashboard"><i class="fa fa-home"></i></router-link>
					Report Desk ({{ reported }})
				</h4>
			</div>
		</div>

		<template v-if="!limitReached && isPresent">
		
			<template v-if="state == 'accepting' || state == 'reporting'">
				
				<div style="padding: 10px 0;" class="clearfix">
					<div class="btn-group pull-left clearfix">
						<button @click="position = 'left'" type="button" class="btn btn-info"><i class="fa fa-toggle-left"></i></button>
						<button @click="position = 'middle'" type="button" class="btn btn-info"><i
								class="fa fa-align-justify"></i></button>
						<button @click="position = 'right'" type="button" class="btn btn-info"><i
								class="fa fa-toggle-right"></i></button>
						<a target="_blank" class="btn btn-warning" :href="apiOrigin + '/image-viewer/' + report?.id"><i
								class="fa fa-image"></i></a>
					</div>
		
					<div v-if="state == 'reporting'" class="pull-right">
						<AutoComplete v-model="template" :dropdown="true" placeholder="Select Template"
							:suggestions="filteredTemplates" @item-select="selectTemplate" @complete="searchTemplates($event)"
							field="title" />
					</div>

					<button @click="showNotesEditor = !showNotesEditor" type="button" class="btn btn-primary pull-right" title="Notes" style="margin-right: 10px"><i class="fa fa-sticky-note"></i></button>
					
					<span class="pull-right" style="margin-top: 8px; padding-right: 15px; font-weight: bold;">
						<span v-if="reportType == 'open'" class="text-info">System Pool</span>
						<span v-if="reportType == 'direct'" class="text-success">Direct Pool</span>
						<span v-if="reportType == 'review'" class="text-warning">Review Pool</span>
					</span>
		
					<div style="margin-left: 180px; margin-right: 255px;">
		
						{{ report?.id }} <b>|</b> {{ report?.patient_name }} <b>|</b> {{ report?.patient_age }} <b>|</b> <span style="text-transform: capitalize;">{{ report?.patient_gender }}</span> <b>| </b>
						<b>{{ report?.test.name }}</b>
		
		
						<div>
							{{ report?.history }}
							<span v-if="report?.review_notes"> <b>|</b> {{ report?.review_notes }}</span>
						</div>
		
						<div>
							<span v-if="historyAudioUrl">
								History: <audio :src="historyAudioUrl" controls></audio>
								&nbsp; &nbsp; 
							</span>
							<span v-if="reviewAudioUrl">
								Review: <audio :src="reviewAudioUrl" controls></audio>
							</span>
						</div>
					</div>
		
					
				</div>
		
				<div class="clearfix"></div>
				<div class="row">
					<div :style="{ display: position == 'left' ? 'none' : 'block' }"
						:class="{ 'col-sm-6': position == 'middle', 'col-sm-12': position == 'right' }">
						<div style="width: 80px; float: left;">
							<div style="margin-bottom: 4px;" v-for="image in allImages" :key="image">
								<img @click="showImage = image" style="max-width: 100%; cursor: pointer;"
									:src="apiOrigin + '/images/radiology/' + image" alt="">
							</div>
						</div>
						<div style="margin-left: 90px;">
							<img v-if="showImage" style="display: block; margin: auto; max-width: 100%" :src="apiOrigin + '/images/radiology/' + showImage"
								alt="">
						</div>
					</div>
					<div :style="{ display: position == 'right' ? 'none' : 'block' }"
						:class="{ 'col-sm-6': position == 'middle', 'col-sm-12': position == 'left' }">

						<template v-if="state == 'reporting'">

							<template v-if="!showNotesEditor">
								<ckeditor style="background: #fff; min-height: 300px;" :editor="editor" :config="editorConfig"
									v-model="editorData" @ready="onReady"></ckeditor>
								<div style="padding: 10px 0; padding-bottom: 30px;" class="clearfix">
									<div class="pull-right">
										<button @click="publishReport" type="button" class="btn btn-success btn-lg">
											<i class="fa fa-paper-plane"></i> Check &amp; Publish
											<span v-if="state == 'reporting'"> ({{ reportTimeoutText }})</span>
											<!-- reportType == 'open' &&  -->
										</button>
									</div>
									<button @click="draftReport" style="margin-right: 10px;" type="button" class="btn btn-primary pull-left">
										Save Draft
									</button>
									<button v-if="reportType == 'open' && false" type="button" class="btn btn-danger pull-left" style="margin-left: 10px;" @click="releaseReport">Release</button>
								</div>
							</template>

							<template v-if="showNotesEditor">

								<div style="min-height: 400px;">
									<textarea v-model="notes" class="form-control" rows="5" placeholder="Notes"></textarea>
								</div>

								<div style="padding: 10px 0; padding-bottom: 30px;" class="clearfix">
									<button type="button" class="btn btn-lg btn-success pull-right" @click="saveNotes">
										Save Notes
										<span v-if="state == 'reporting'"> ({{ reportTimeoutText }})</span>
										<!-- reportType == 'open' &&  -->
									</button>
								</div>
							
							</template>
						</template>
						<template v-if="state == 'accepting'">
							<div>
								<div style="min-height: 400px;">
									<div>
										<textarea v-if="showNotesEditor" v-model="notes" class="form-control" rows="5" placeholder="Notes"></textarea>
									</div>


									<div v-if="true" style="font-size: 20px; padding-top: 180px; text-align: center; font-weight: bold;">
										
									</div>

								</div>
								<div style="padding: 10px 0; padding-bottom: 30px;" class="clearfix">
									<button type="button" class="btn btn-lg btn-success pull-right" @click="acceptReport">
										Accept<span v-if="acceptTimeoutText"> ({{ acceptTimeoutText }})</span>
									</button>
									<button v-if="false" type="button" class="btn btn-danger pull-left" @click="releaseReport">Skip</button>
								</div>
							</div>
						</template>

					</div>
				</div>
			</template>

			<template v-if="state == 'waiting'">
				<div style="font-size: 60px; padding-top: 200px; margin: auto; width: 355px; font-weight: bold;">
					{{waitingText}}
					<div style="font-size: 15px;">Please wait. We are searching cases for you.</div>
				</div>
			</template>

			<template v-if="state == 'inactive'">
				<div style="font-size: 20px; padding-top: 200px; text-align: center; font-weight: bold;">
					We found you inactive. Please let us know when you are available again.
					<div style="margin-top: 50px;">
						<button type="button" class="btn btn-primary btn-lg" @click="makeMeAvailable">I'm Available</button>
					</div>
				</div>
			</template>

		</template>

		<template v-if="limitReached && isPresent">
			<div style="font-size: 30px; padding-top: 200px; text-align: center; font-weight: bold;">
				You can simultaneously report in maximum {{ limit }} window.
			</div>
		</template>



		<div v-if="!isPresent" style="padding: 180px 0; text-align: center;">
			<h3>You are absent</h3>
		</div>


	</div>
</template>

<script>
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import Toast from 'primevue/toast';
import AutoComplete from 'primevue/autocomplete';
import InputSwitch from 'primevue/inputswitch';

export default {
	components: {
		Toast,
		AutoComplete,
		InputSwitch
	},
	name: 'app',
	data() {
		return {
			editor: DecoupledEditor, //ClassicEditor,
			editorData: '',
			editorConfig: {},
			report: null,

			position: 'middle',

			allImages: [],
			showImage: "",

			templates: [],
			filteredTemplates: [],
			template: null,

			historyAudioUrl: '',
			reviewAudioUrl: '',

			isPresent: true,

			lastActive: new Date().getTime(),
			timerIncreased: false,


			limitReached: false,
			limit: 0,

			reportId: 0,
			reportType: '',
			reportTimeout: 0,
			reportTill: 0,
			reportTimeoutText: '00 : 00',


			acceptTimeout: 0,
			acceptTill: 0,
			acceptTimeoutText: '00 : 00',

			notes: '',
			showNotesEditor: false,

			state: 'waiting', // out, inactive, waiting, accepting, reporting
			waitingSince: new Date().getTime(),
			waitingText: '00 : 00 : 00',
			waitingUpdateInterval: 0,


			openPoolCount: 0,
			directPoolCount: 0,
			reviewPoolCount: 0,
			reported: 0,

		};
	},	
	methods: {
		updateLastActive(){
			this.lastActive = new Date().getTime();
		},

		setupSocEvents(){
			{
				let fd = {
					state: this.state,
					reportId: this.reportId
				}
				soc.emit("startReporting", fd);


				this.waitingSince = new Date().getTime();
			}
		},

		onReport(d){

			console.log("on report: ", d);

			let alreadyLoaded = false;
			if(d.report.id == this.report?.id) alreadyLoaded = true;

			
			this.state = d.state;
			
			this.reportId = d.report.id;
			this.report = d.report;

			this.template = null;

			if(!alreadyLoaded){
				this.editorData = this.report.report ? this.report.report : '';

				if(!this.editorData && this.state == 'reporting'){
					soc.emit("draftReport", {
						reportId: this.report.id,
						report: ' '
					});
					soc.once("draftReport", d=>{
						console.log("Silent draft: ", d);
					});
				}

				notiAudio.play();
			}

			this.allImages = this.report.images.split(",");
			this.showImage = this.allImages[0];
			
			if(this.report.status == 'review'){
				this.reportType = 'review'
			}else{
				if(this.report.assign_type == 'all'){
					this.reportType = 'open';
				}else{
					this.reportType = 'direct';
				}
			}

			this.historyAudioUrl = '';
			this.reviewAudioUrl = '';

			if(d.history_audio_file){
				this.historyAudioUrl = this.apiOrigin + "/" + 'images/radiology/' + d.history_audio_file;
			}
			if(d.review_audio_file){
				this.reviewAudioUrl = this.apiOrigin + "/" + 'images/radiology/' + d.review_audio_file;
			}

			this.notes = this.report.notes ? this.report.notes : '';
			this.showNotesEditor = false;

			if(this.state == 'accepting'){
				this.acceptTill = d.acceptTill;

				// let diff = this.acceptTill - new Date().getTime();

				// if(diff < 55*1000 || diff > 65*1000) alert("Your system time is not accurate. You should update it immediately.");
			}

			this.reportTill = d.reportTill;

			// if(!this.report.report){
			// 	this.post('radiologist/draft-report', {
			// 		report: ' ',
			// 		report_id: this.$route.params['report_id'],
			// 	}).then(res=>{
			// 	});
			// }

		},

		acceptReport(){
			soc.emit("acceptReport", {
				reportId: this.reportId,
				notes: this.showNotesEditor ? this.notes : ''
			})
		},
		releaseReport(){
			soc.emit("releaseReport", {
				reportId: this.reportId
			})
		},

		onUpdateStats(d){
			console.log("Update Stats", d);

			this.openPoolCount = d.open;
			this.directPoolCount = d.direct;
			this.reviewPoolCount = d.review;
			this.reported = d.reported;
		},
		onLimitReached(limit){
			this.limit = limit;
			this.limitReached = true;
		},
		onStateChange(d){
			this.state = d.state;

			console.log("On state change: ", d);

			if(d.state == 'inactive'){
				this.report = null;
				this.reportId = 0;
			}else if(d.state == 'waiting'){
				this.waitingSince = new Date().getTime();
			}else if(d.state == 'reporting'){
				if(d.reportTill) this.reportTill = d.reportTill;

				if(!this.editorData && this.state == 'reporting'){
					soc.emit("draftReport", {
						reportId: this.report.id,
						report: ' '
					});
					soc.once("draftReport", d=>{
						console.log("Silent draft: ", d);
					});
				}

			}
		},
		onIncreaseTimer(d){
			this.timerIncreased = false;

			if(d.state == 'accepting'){
				this.acceptTill = d.acceptTill;
			}else if(d.state == 'reporting'){
				this.reportTill = d.reportTill;
			}

			console.log("increaseTimer", d);
		},

		makeMeAvailable(){
			soc.emit('makeMeAvailable', true);
		},

		updateWaitingText(){
			{
				let now = new Date().getTime();
	
				let diff = now - this.waitingSince;
				diff = Math.round(diff / 1000);
	
				let hours = Math.floor(diff / 60 / 60);
				diff = diff - hours*60*60;
				hours = String(hours);
				if(hours.length == 1) hours = "0"+hours;
	
				let minutes =  Math.floor(diff / 60 );
				diff = diff - minutes*60;
				minutes = String(minutes)
				if(minutes.length == 1) minutes = "0"+minutes;
				
				let seconds = String(diff);
				if(seconds.length == 1) seconds = "0"+seconds;
	
				this.waitingText = hours+' : '+minutes+' : '+seconds;
			}
			document.title = "Teleradio";

			if(this.state == 'accepting' && this.acceptTill){
				let now = new Date().getTime();
	
				let diff = this.acceptTill - now;
				diff = Math.round(diff / 1000);

				if(diff < 0) diff = 0;
	
				let minutes =  Math.floor(diff / 60 );
				diff = diff - minutes*60;
				minutes = String(minutes)
				if(minutes.length == 1) minutes = "0"+minutes;
				
				let seconds = String(diff);
				if(seconds.length == 1) seconds = "0"+seconds;
	
				this.acceptTimeoutText = minutes+' : '+seconds;

				
				if(this.acceptTimeoutText < '00 : 10' && !this.timerIncreased ){
					let lad = new Date().getTime() - this.lastActive;
					if(lad < 60*1000){
						this.timerIncreased = true;
						soc.emit('increaseTimer', true);
					}
				}

				document.title = diff % 2 == 0 ? "("+seconds+") New Case" : 'Attention Please...';
			}

			if(this.state == 'reporting' && this.reportTill){
				let now = new Date().getTime();
	
				let diff = this.reportTill - now;
				diff = Math.round(diff / 1000);

				if(diff < 0) diff = 0;
	
				let minutes =  Math.floor(diff / 60 );
				diff = diff - minutes*60;
				minutes = String(minutes)
				if(minutes.length == 1) minutes = "0"+minutes;
				
				let seconds = String(diff);
				if(seconds.length == 1) seconds = "0"+seconds;
	
				this.reportTimeoutText = minutes+' : '+seconds;

				if(this.reportTimeoutText < '00 : 10' && !this.timerIncreased ){
					let lad = new Date().getTime() - this.lastActive;
					if(lad < 60*1000){

						console.log(this.reportTimeoutText, '00 : 10', this.reportTimeoutText < '00 : 10');

						this.timerIncreased = true;
						soc.emit('increaseTimer', true);
					}
				}
			}

		},
		


		onReady(editor) {
			editor.ui.getEditableElement().parentElement.insertBefore(
				editor.ui.view.toolbar.element,
				editor.ui.getEditableElement()
			);
		},
		searchTemplates(e) {
			this.filteredTemplates = this.templates.filter(t => t.title.toLowerCase().indexOf(e.query.toLowerCase()) != -1)
		},
		selectTemplate() {
			if (this.template) this.editorData = this.template.content
		},
		async draftReport() {
			soc.emit("draftReport", {
				reportId: this.report.id,
				report: this.editorData
			});
			soc.once("draftReport", d=>{
				if(d.success){
					this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Report drafted successfully', life: 3000 });
				}else{
					this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong. Please try again later.', life: 3000 });
				}
			})
		},
		async publishReport() {
			if(this.editorData.trim().length < 20){
				return this.$toast.add({ severity: 'error', summary: 'Report Too Short', detail: 'This report is too short. Please type some more to publish this report.', life: 3000 });
			}

			soc.emit("publishReport", {
				reportId: this.report.id,
				report: this.editorData
			});
			soc.once("publishReport", d=>{
				if(d.success){
					this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Report published successfully', life: 3000 });

					this.$router.push("/radiologist/report/0");
					this.state = 'waiting';
					this.waitingSince = new Date().getTime();
				}else{
					this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong. Please try again later.', life: 3000 });
				}
			});
			return;
			/*
			let r = await this.post('radiologist/publish-report', {
				report: this.editorData,
				report_id: this.$route.params['report_id'],
			});
			if (r.success) {
				this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Report published successfully', life: 3000 });
				// this.$router.push("/radiologist/reports/pending");
				if (this.report.status == 'pending') {
					this.$router.push("/radiologist/reports/pending");
				} else {
					this.$router.push("/radiologist/reports/review");
				}
			} else {
				this.$router.push("/radiologist/reports/pending");
			}
			*/
		},

		saveNotes(){
			if(this.notes.trim().length < 5){
				return this.$toast.add({ severity: 'error', summary: 'Notes Too Short', detail: 'Notes for this report is too short. Please type some more to send the notes.', life: 3000 });
			}
			soc.emit("saveNotes", {
				reportId: this.reportId,
				notes: this.notes,
			})
		},

		async togglePresent() {
			await this.post('radiologist/toggle-present', {
				isPresent: this.isPresent
			})
		},

	},
	async mounted() {
		setTimeout(()=>{
			soc.on('connect', this.setupSocEvents)

			soc.on("report", this.onReport);
			soc.on("timeout", this.onTimeout);
			soc.on("updateStats", this.onUpdateStats);
			soc.on("limitReached", this.onLimitReached);
			soc.on("stateChange", this.onStateChange);
			soc.on("increaseTimer", this.onIncreaseTimer);

			this.setupSocEvents();
		}, 0)

		document.body.addEventListener('mousemove', this.updateLastActive);
		document.body.addEventListener('keyup', this.updateLastActive);

		this.waitingUpdateInterval = setInterval(this.updateWaitingText, 1000)

		this.reportId = Number(this.$route.params['report_id']);


		this.get('radiologist/get-is-present').then(res => {
			this.isPresent = res.isPresent;
		})

		this.templates = await this.get("radiologist/load-templates");
		return;

		/*
		let timerIncreased = false;
		let w = () => {
			if (!document.body.contains(this.$refs.container)) return;

			if (this.report?.accepted_at) {
				let t1 = new Date(this.report.accepted_at);
				t1.setTime(t1.getTime() + 10 * 60 * 1000);
				let t2 = new Date();
				let td = t1.getTime() - t2.getTime();
				td = td / 1000;
				if (td < 0) td = 0;
				let m = String(Math.floor(td / 60));
				if (m.length == 1) m = '0' + m;
				let s = String(Math.floor(td % 60));
				if (s.length == 1) s = '0' + s;

				this.report.timer = '00:' + m + ':' + s;

				if (this.report.status != 'review' && this.report.timer < "00:02:00" && !timerIncreased) {
					timerIncreased = true;
					if (confirm("Your reporting timer is about to expire, do you want to extend for 10 more minutes?")) {
						this.post("radiologist/increase-timer", { report_id: this.report.id }).then(r => {
							if (r.success) {
								this.report.accepted_at = r.accepted_at;
								this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Timer increased successfully', life: 3000 });
							} else {
								this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Failed to increase timer', life: 3000 });
							}
						});
					}
				}


				if (this.report.timer == '00:00:00' && this.report.status == 'pending' && this.report.assign_type == 'all') {
					this.$router.push("/radiologist/reports/pending");
				}
			}
			setTimeout(w, 1000);
		}
		// w();


		this.post("radiologist/load-report", {
			report_id: this.$route.params['report_id'],
		}).then(res => {
			if (res.success) {
				this.report = res.report;
				this.editorData = this.report.report ? this.report.report : '';
				this.allImages = this.report.images.split(",");
				this.showImage = this.allImages[0];


				if(res.history_audio_file){
					this.historyAudioUrl = this.apiOrigin + "/" + 'images/radiology/' + res.history_audio_file;
				}
				if(res.review_audio_file){
					this.reviewAudioUrl = this.apiOrigin + "/" + 'images/radiology/' + res.review_audio_file;
				}

				if(!this.report.report){
					this.post('radiologist/draft-report', {
						report: ' ',
						report_id: this.$route.params['report_id'],
					}).then(res=>{
					});
				}

			} else {
				// this.$router.push("/radiologist/reports/pending");
			}
		});
		*/
		
	},
	unmounted() {
		soc.emit("exitReportingPage", true);

		soc.off('connect', this.onConnect);
		soc.off('report');
		soc.off('timeout');
		soc.off('updateStats');
		soc.off('limitReached')
		soc.off('stateChange')
		soc.off("increaseTimer");

		document.body.removeEventListener('mousemove', this.updateLastActive);
		document.body.removeEventListener('keyup', this.updateLastActive);

		console.log("this.waitingUpdateInterval: ", this.waitingUpdateInterval);

		clearInterval(this.waitingUpdateInterval);

		document.title = "Teleradio";

	},
}
</script>
<style scoped></style>